<div class="home" fxLayout="column">
  <div>
    <img src="../assets/home/hero_image.svg" />
    <div fxLayout="column" fxLayoutAlign="start center">
      <h1>get more done</h1>
      <h2><span>Let’s</span> build <span>something</span> Together.</h2>
      <p>
        we focus on driving a prioritized backlog of items and deliver working
        software in bi-weekly frequencies to our customers. Our customers will
        see live working software in a 2 week increments. We focus on getting
        quick feedback and delivering software fast.
      </p>
    </div>
  </div>
  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="3.25rem">
    <div gdAreas="docker aws firebase nodejs npm" gdGap="5.75rem">
      <img src="../assets/home/docker.svg"/>
      <img src="../assets/home/aws.svg"/>
      <img src="../assets/home/firebase.svg"/>
      <img src="../assets/home/nodejs.svg"/>
      <img src="../assets/home/npm.svg"/>
    </div>
    <div gdAreas="bootstrap angular layers atlassian polymer" gdGap="4.5rem">
      <img src="../assets/home/bootstrap.svg"/>
      <img src="../assets/home/angular.svg"/>
      <img src="../assets/home/layers.svg"/>
      <img src="../assets/home/atlassian.svg"/>
      <img src="../assets/home/polymer.svg"/>
    </div>
  </div>
</div>
