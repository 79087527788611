<div class="solutions">
  <div gdAreas="dev pm | pd qa" gdGap="2rem">
    <div gdArea="dev" gdAreas="img h1 | img p" gdColumns="5rem auto" gdRows="min-content auto" gdGap="0 3rem">
      <div gdArea="img" fxLayout="row" fxLayoutAlign="center start">
        <img src="../assets/solutions/smartphone.svg" />
      </div>
      <h1 gdArea="h1">Development</h1>
      <p gdArea="p">
        Using the latest frameworks and security standards. Setting up your
        product to be SEO, localized and A11Y compliant from the start. From
        Client-Side, Server-Side, Middleware, API’s and User Data Management,
        Transformation and migration of on-premises servers to the cloud (AWS, GCP, etc).
      </p>
    </div>
    <div gdArea="pm" gdAreas="img h1 | img p" gdColumns="5rem auto" gdRows="min-content auto" gdGap="0 3rem">
      <div gdArea="img" fxLayout="row" fxLayoutAlign="center start">
        <img src="../assets/solutions/monitor.svg" />
      </div>
      <h1 gdArea="h1">Project Management</h1>
      <p gdArea="p">
        <b>Agile Development:</b> Transforming organizational change to adopt a new
        methodology of delivering software Identifying risk analysis and
        escalation of issues and managing deliverable. Building a prioritized
        backlog of requirements and user stories. Performing Scrum Rituals
        to obtain maximum efficiency and effectiveness in software delivery.
      </p>
    </div>
    <div gdArea="pd" gdAreas="img h1 | img p" gdColumns="5rem auto" gdRows="min-content auto" gdGap="0 3rem">
      <div gdArea="img" fxLayout="row" fxLayoutAlign="center start">
        <img src="../assets/solutions/layers.svg" />
      </div>
      <h1 gdArea="h1">Product Design</h1>
      <p gdArea="p">
        We create design given the product audience, goal, business domain, technology and the platform for the solution.
      </p>
    </div>
    <div gdArea="qa" gdAreas="img h1 | img p" gdColumns="5rem auto" gdRows="min-content auto" gdGap="0 3rem">
      <div gdArea="img" fxLayout="row" fxLayoutAlign="center start">
        <img src="../assets/solutions/test.svg" />
      </div>
      <h1 gdArea="h1">Quality Assurance</h1>
      <p gdArea="p">
        Our QA team ensures high standard of quality of products delivered.
      </p>
    </div>
  </div>
</div>
<div class="get-started">
  <img src="../assets/solutions/get_started.svg" />
  <div fxLayout="column" fxLayoutAlign="end start">
    <h1>Let agile technology get you back on track</h1>
    <h2>Get started now</h2>
    <p>
      Get to know our company. Get to know what we do.
    </p>
    <a href="https://drive.google.com/file/d/1t5PftPMdzXTny7PH942LzKNBjzQbbTza/view?fbclid=IwAR0hXjxdqN0ThcGtx7TCa7fVigfn9DhL4LFvMdgO4g0qMv6wzmBvnOCYfY0">
      <button mat-stroked-button>Download our White Paper</button>
    </a>
  </div>
</div>
