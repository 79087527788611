<div class="about">
  <div>
    <img src="../assets/about/clouds.svg" />
    <div fxLayout="row" fxLayoutGap="6.5rem">
      <div fxFlex="1fr">
        <h1>consultants who cares for customer success.</h1>
        <h2>Plan <span>and</span> manage</h2>
        <p>
          <b>Agile Technology Expansion</b> is a dedicated group of consultants who
          cares for customer success. We build custom software for businesses
          and corporations that would like to upgrade their existing systems.
          We hope to earn your business. We are prepared to provide a proof of
          concept, a detailed interview, and give you total satisfaction and
          confidence on what we can deliver.
        </p>
        <button mat-flat-button color="primary" (click)="gotoSolutions()">Our Solutions <img class="chevron" src="../assets/btn/chevron.svg" /></button>
      </div>
      <div fxFlex="1fr">
        <h1>meet the team</h1>
        <h2>Founding <span>Team Launchers.</span> <img src="../assets/navigation/Telegram_logo.svg" /></h2>
        <div gdAreas="nathan ryan | atiyah evan | danny evil" gdColumns="1fr 1fr" gdGap="2rem 1rem">
          <div gdArea="nathan" gdAreas="img name | img title" gdColumns="min-content auto" gdGap=".25rem 1rem">
            <img gdArea="img" src="../assets/about/nathan.svg" />
            <h3 gdGridAlign="start end">Nathan Melton</h3>
            <h4>Software Developer</h4>
          </div>
          <div gdArea="ryan" gdAreas="img name | img title" gdColumns="min-content auto" gdGap=".25rem 1rem">
            <img gdArea="img" src="../assets/about/ryan.svg" />
            <h3 gdGridAlign="start end">Ryan Tibbetts</h3>
            <h4>Software Developer</h4>
          </div>
          <div gdArea="atiyah" gdAreas="img name | img title" gdColumns="min-content auto" gdGap=".25rem 1rem">
            <img gdArea="img" src="../assets/about/atiyah.svg" />
            <h3 gdGridAlign="start end">Atiyah Thomas</h3>
            <h4>Principal UX</h4>
          </div>
          <div gdArea="evan" gdAreas="img name | img title" gdColumns="min-content auto" gdGap=".25rem 1rem">
            <img gdArea="img" src="../assets/about/evan.svg" />
            <h3 gdGridAlign="start end">Evan Price</h3>
            <h4>Software Engineer</h4>
          </div>
          <div gdArea="danny" gdAreas="img name | img title" gdColumns="min-content auto" gdGap=".25rem 1rem">
            <img gdArea="img" src="../assets/about/danny.svg" />
            <h3 gdGridAlign="start end">Danny Huynh</h3>
            <h4>Founder/CEO</h4>
          </div>
          <div gdArea="evil" gdAreas="img name | img title" gdColumns="min-content auto" gdGap=".25rem 1rem">
            <!-- <img class="evil-danny" gdArea="img" src="../assets/about/danny.svg" />
            <h3 gdGridAlign="start end">Evil Danny Huynh</h3>
            <h4>Evil Founder/CEO</h4> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="row">
    <div fxFlex="1fr" fxLayout="column" fxLayoutAlign="center end">
      <img src="../assets/about/Group.svg"/>
      <h1>96%</h1>
      <h2>Of our Business is from customer referrals.</h2>
    </div>
    <div fxFlex="1fr" fxLayout="column" fxLayoutAlign="center start">
      <img src="../assets/about/Group_4.svg"/>
      <h1>312</h1>
      <h2>Clients we’ve collectively assisted with launching.</h2>
    </div>
  </div>
</div>
