import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  @Output() viewSolutions = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  gotoSolutions() {
    this.viewSolutions.emit('solutions');
  }

}
