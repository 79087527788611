<div class="landing-page">
  <img class="background" src="../assets/landing-page/homepage-background.svg" />
  <img class="space-lady" src="../assets/landing-page/home-page-astro-lady.svg"/>
  <div class="landing-page-container" fxLayout="row" fxLayoutAlign="start center">
    <div class="welcome">
      <h1>Agile Technology Expansion</h1>
      <h2>We're an independent design & development team.</h2>
      <p>
        Transforming organizational change to adopt a new methodology of
        delivering software. Using the latest technology to build a live full
        fledged functioning application to fulfill your business needs.
      </p>
      <a href="https://drive.google.com/file/d/1t5PftPMdzXTny7PH942LzKNBjzQbbTza/view?fbclid=IwAR0hXjxdqN0ThcGtx7TCa7fVigfn9DhL4LFvMdgO4g0qMv6wzmBvnOCYfY0">
        <button mat-stroked-button>Download our White Paper</button>
      </a>
    </div>
  </div>
</div>
