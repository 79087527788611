<div class="footer">
  <div gdAreas="title email spacer | sub email spacer " gdRows="auto 1fr" gdColumns="1fr 1fr 1fr" gdGap="0 2rem">
    <h1 gdArea="title">Let’s work Together</h1>
    <p gdArea="sub">
      We’d love to hear about your product.<br />
      Let’s talk and turn your great idea into something even greater.
    </p>
    <div gdArea="email" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="1rem">
      <!-- <mat-form-field fxFlex="1fr" appearance="fill">
        <mat-label>Email Address</mat-label>
        <input matInput>
      </mat-form-field>
      <button mat-flat-button color="primary">Send</button> -->
      <mat-form-field fxFlex="1fr" appearance="fill">
        <mat-label>Reach us at</mat-label>
        <input matInput [value]="'dhuynh@atexpansion.com'">
      </mat-form-field>
      <button mat-flat-button color="primary" [cdkCopyToClipboard]="email" (click)="copySnackbar('Email Copied')">Copy</button>
    </div>
    <div gdArea="spacer"></div>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <img src="../assets/footer/footer-background.svg"/>
    <div gdAreas="logo logo logo | navigation copyright social" gdRows="min-content min-content" gdColumns="min-content 1fr min-content" gdGap="1.5rem">
      <img gdArea="logo" src="../assets/navigation/logo.svg" />
      <div gdArea="navigation" fxLayout="row" fxLayoutGap="1rem">
        <button mat-button (click)="gotoHome()">Home</button>
        <button mat-button (click)="gotoSolutions()">Solutions</button>
        <button mat-button (click)="gotoAbout()">About</button>
        <button mat-button (click)="contact()">Contact</button>
      </div>
      <div gdArea="copyright" fxLayout="row" fxLayoutAlign="center center">Copyright © 2021 Agile Technology Expansion</div>
      <div gdArea="social" fxLayout="row" fxLayoutGap="2rem">
        <!-- <a fxLayout="row" fxLayoutAlign="center center" href="https://www.facebook.com/AgileTechExp">
        <img src="../assets/navigation/Vector.svg" />
      </a> -->
        <a fxLayout="row" fxLayoutAlign="center center" href="https://www.facebook.com/AgileTechExp">
          <img src="../assets/navigation/Vector-1.svg" />
        </a>
        <a fxLayout="row" fxLayoutAlign="center center" href="https://www.linkedin.com/company/agile-technology-expansion/">
          <img src="../assets/navigation/Vector-2.svg" />
        </a>
      </div>
    </div>
  </div>
</div>
