import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @Input() navScroll: boolean;
  @Output() viewHome = new EventEmitter<string>();
  @Output() viewSolutions = new EventEmitter<string>();
  @Output() viewAbout = new EventEmitter<string>();
  @Output() viewLanding = new EventEmitter<string>();
  @Output() viewContact = new EventEmitter<string>();

  email = "Tibbetts.E.Ryan@gmail.com";

  constructor(private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  gotoHome() {
    this.viewHome.emit('home');
  }

  gotoSolutions() {
    this.viewSolutions.emit('solutions');
  }

  gotoAbout() {
    this.viewAbout.emit('about');
  }

  gotoLanding() {
    this.viewLanding.emit('landing');
  }

  contact() {
    this.viewContact.emit('contact');
  }

  copySnackbar(message: string) {
    this._snackBar.open(message, '', {
      duration: 2000,
    });
  }

}
