<div [class]="navScroll?'nav scrolling':'nav landing'"
[gdAreas]="navScroll?'logo navigation spacer social signup':'logo logo logo logo | spacer spacer navigation signup'"
[gdColumns]="navScroll?'min-content min-content auto min-content min-content':'min-content auto min-content min-content'">
  <div gdArea="logo" fxLayout="row" fxLayoutAlign="start center">
    <img [style]="navScroll?{opacity:0}:{opacity:1}" src="../assets/navigation/logo.svg" />
    <img [style]="navScroll?{opacity:1}:{opacity:0}" (click)="gotoLanding()" src="../assets/navigation/Telegram_logo.svg" />
  </div>
  <div gdArea="spacer"></div>
  <div gdArea="navigation" fxLayout="row" fxLayoutGap="1rem">
    <button mat-button (click)="gotoHome()">Home</button>
    <button mat-button (click)="gotoSolutions()">Solutions</button>
    <button mat-button (click)="gotoAbout()">About</button>
    <button mat-button (click)="contact()">Contact</button>
  </div>
  <div *ngIf="navScroll" gdArea="social" fxLayout="row" fxLayoutGap="2rem">
    <!-- <a fxLayout="row" fxLayoutAlign="center center" href="https://www.facebook.com/AgileTechExp">
      <img src="../assets/navigation/Vector.svg" />
    </a> -->
    <a fxLayout="row" fxLayoutAlign="center center" href="https://www.facebook.com/AgileTechExp">
      <img src="../assets/navigation/Vector-1.svg" />
    </a>
    <a fxLayout="row" fxLayoutAlign="center center" href="https://www.linkedin.com/company/agile-technology-expansion/">
      <img src="../assets/navigation/Vector-2.svg" />
    </a>
    <div></div>
  </div>
  <div gdArea="signup"></div>
  <!-- <button gdArea="signup" mat-flat-button color="primary" (click)="contact()">Sign Up<img class="chevron" src="../assets/btn/chevron.svg"/></button> -->
</div>
