import { Component, ViewChild, HostListener, Input, Output, ElementRef } from '@angular/core';
import { LandingPageComponent } from "./landing-page/landing-page.component";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  @ViewChild('home') home: HTMLElement;
  @ViewChild('solutions') solutions: HTMLElement;
  @ViewChild('about') about: HTMLElement;
  @ViewChild('landing') landing: HTMLElement;
  @ViewChild('contact') contact: HTMLElement;
  @HostListener('window:scroll', ['$event']) onScrollEvent($event) {
    if (0 < window.scrollY) {
      this.navHover = true;
    } else {
      this.navHover = false;
    }
  }

  public navHover: boolean;

  ngOnInit(): void {
    if (0 < window.scrollY) {
      this.navHover = true;
    } else {
      this.navHover = false;
    }
  }

  ngAfterViewInit(): void {
  }

  scrollToElement(htmlElement: string) {
    this[htmlElement].nativeElement.scrollIntoView({ behavior: 'smooth' });
  }
}
