import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  @Input() navScroll: boolean;
  @Output() viewHome = new EventEmitter<string>();
  @Output() viewSolutions = new EventEmitter<string>();
  @Output() viewAbout = new EventEmitter<string>();
  @Output() viewLanding = new EventEmitter<string>();
  @Output() viewContact = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  gotoHome() {
    this.viewHome.emit('home');
  }

  gotoSolutions() {
    this.viewSolutions.emit('solutions');
  }

  gotoAbout() {
    this.viewAbout.emit('about');
  }

  gotoLanding() {
    this.viewLanding.emit('landing');
  }

  contact() {
    this.viewContact.emit('contact');
  }

}
