<div class="page-container" gdAreas="landing | home | solutions | about | footer" gdRows="auto auto auto auto auto">
  <div [class]="navHover?'navigation nav-hover':'navigation'">
    <app-navigation [navScroll]="navHover" (viewHome)="scrollToElement($event)" (viewSolutions)="scrollToElement($event)" (viewAbout)="scrollToElement($event)" (viewLanding)="scrollToElement($event)" (viewContact)="scrollToElement($event)"></app-navigation>
  </div>
  <div #landing>
    <app-landing-page gdArea="landing"></app-landing-page>
  </div>
  <div gdArea="home" class="nav-container">
    <div #home></div>
    <app-home></app-home>
  </div>
  <div gdArea="solutions" class="nav-container">
    <div #solutions></div>
    <app-solutions></app-solutions>
  </div>
  <div gdArea="about" class="nav-container">
    <div #about></div>
    <app-about (viewSolutions)="scrollToElement($event)"></app-about>
  </div>
  <div #contact gdArea="footer">
    <app-footer (viewHome)="scrollToElement($event)" (viewSolutions)="scrollToElement($event)" (viewAbout)="scrollToElement($event)" (viewLanding)="scrollToElement($event)" (viewContact)="scrollToElement($event)"></app-footer>
  </div>
</div>
